

























































































































































































































































import {Vue, Component} from "vue-property-decorator";
import {Micro} from "@/request/plat/Micro";
import {Common} from "@/request/Common";
import config from "@/config";
import {Merchants} from "@/request/plat/Merchants";

@Component({
    name: 'plat_micro'
})
export default class plat_micro extends Vue {
    modal: any = Micro;
    corporationList: Array<any> = [];
    provinceList: Array<any> = [];
    cityList: Array<any> = [];
    businessTypeList: Array<any> = [];
    microTypeList: Array<any> = [];
    modules: Array<any> = [
        {
            name: '分销',
            value: 1
        },
        {
            name: '团购',
            value: 2
        },
        {
            name: '社区团购',
            value: 3
        },
        {
            name: '直播',
            value: 4
        }
    ];
    pwdModal: any = {
        show: false,
        content: ''
    };
    cfgModal: any = {
        show: false,
        info: {
            bstoreShow: false,
            headerBackGround: '',
            askInfoImage: '',
            askPhoneImage: '',
            originalId: '',
            navitationBarStyle: {
                backgroundTextStyle: "dark",
                navigationBarBackgroundColor: "#fff",
                navigationBarTextStyle: "black"
            },
            tabBar: []
        }
    };
    qrCodeModal: any = {
        show: false,
        title: '',
        path: ''
    };
    templateModal: any = {
        show: false,
        row: {},
        tempList: [],
        loading: true,
        columns: [
            {
                title: '小程序版本',
                key: 'user_version',
                minWidth: 120
            },
            {
                title: '模版ID',
                key: 'template_id',
                minWidth: 100
            },
            {
                title: '描述',
                key: 'user_desc',
                minWidth: 140,
                tooltip: true
            },
            {
                title: '添加时间',
                key: 'create_time',
                minWidth: 170
            },
            {
                title: '操作',
                minWidth: 100,
                render: (h: any, p: any) => {
                    let self: any = this;
                    return h('Button', {
                        props: {
                            size: 'small'
                        },
                        on: {
                            click() {
                                let row = self.templateModal.row;
                                self.$Message.loading({content: '加载中'});
                                Micro.getMpCfg(row.id).then((body: any) => {
                                    if (body.code === 0) {
                                        self.$Message.destroy();
                                        let data = body.data;
                                        self.extJson.extAppid = row.id;
                                        self.extJson.window = {
                                            backgroundTextStyle: data.navitationBarStyle.backgroundTextStyle,
                                            navigationBarBackgroundColor: data.navitationBarStyle.navigationBarBackgroundColor,
                                            navigationBarTitleText: row.name,
                                            navigationBarTextStyle: data.navitationBarStyle.navigationBarTextStyle
                                        };
                                        self.extJson.tabBar.list = [];
                                        let pages: Array<string> = [
                                            'pages/index/main',
                                            'pages/shop/main',
                                            'pages/shopping-car/main',
                                            'pages/personal/main'
                                        ];
                                        if (row.microType.indexOf('MY') !== -1) pages.splice(2, 0, 'pages/bespeak/main');
                                        if (data.tabBar.filter((j: any) => j.text !== '').length === 0) {

                                        } else {
                                            data.tabBar.forEach((j: any, ind: number) => {
                                                self.extJson.tabBar.list.push({
                                                    pagePath: pages[ind],
                                                    text: j.text
                                                });
                                                if (ind > 0) {
                                                    self.extJson.extPages[pages[ind]] = {};
                                                    self.extJson.extPages[pages[ind]]['navigationBarTitleText'] = j.text;
                                                }
                                            })
                                        }
                                        self.$Modal.confirm({
                                            title: '提示',
                                            content: '确认上传此版本吗？',
                                            loading: self.templateModal.loading,
                                            onOk() {
                                                Micro.commitCode({
                                                    appId: row.id,
                                                    extJson: JSON.stringify(self.extJson),
                                                    templateId: p.row.template_id,
                                                    userDesc: p.row.user_desc,
                                                    userVersion: p.row.user_version
                                                }).then((body: any) => {
                                                    self.$Modal.remove();
                                                    if (body.code === 0) {
                                                        self.$Message.info('上传成功');
                                                    }
                                                })
                                            }
                                        })
                                    }
                                })
                            }
                        }
                    }, '确认')
                }
            }
        ]
    };
    extJson: any = {
        extEnable: true,
        extAppid: '',
        directCommit: true,
        extPages: {},
        window: {},
        tabBar: {
            list: []
        }
    };
    search: Array<any> = [
        {
            type: 'Input',
            label: '商户名：',
            key: 'name',
            width: '160px',
            clearable: true,
            placeholder: '请填写商户名'
        },
        {
            type: 'Select',
            label: '商户类型：',
            key: 'businessType',
            width: '160px',
            clearable: true,
            filterable: true,
            items() {
                let self: any = this;
                return new Promise(resolve => {
                    Common.businessTypeList().then((body: any) => {
                        let data: any = body.data;
                        resolve(data.map((j: any) => {
                            return {
                                label: j.name,
                                value: j.btype
                            }
                        }));
                    })
                })
            }
        },
        {
            type: 'Select',
            label: '省：',
            key: 'provinceCode',
            width: '160px',
            clearable: true,
            filterable: true,
            items() {
                return new Promise(resolve => {
                    Common.provinceList().then((body: any) => {
                        let data: any = body.data;
                        resolve(data.map((j: any) => {
                            return {
                                label: j.name,
                                value: j.id
                            }
                        }))
                    })
                })
            },
            onchange(obj: {value: string, label: string}) {
                if (obj) {
                    Common.cityList(obj.value).then((body: any) => {
                        let data = body.data;
                        /*this.cityList = data.map((j: any) => {
                            return {
                                label: j.name,
                                value: j.id
                            }
                        })*/
                    })
                }
            }
        },
        {
            type: 'Select',
            label: '市：',
            key: 'cityCode',
            width: '160px',
            clearable: true,
            filterable: true,
            items: this.cityList
        },
        {
            type: 'DatePicker',
            pickerType: 'daterange',
            format: 'yyyy-MM-dd',
            label: '创建时间：',
            width: '220px',
            placeholder: '请选择发送时间'
        }
    ];
    formData: any = {
        id: '',
        secret: '',
        corporationId: '',
        name: '',
        businessType: [],
        managerName: '',
        managerPhone: '',
        cityCode: '',
        desc: '',
        maxStore: 1,
        provinceCode: '',
        serviceEndTime: '',
        version: 1.0
    };
    formRules: any = {
        id: {required: true, trigger: 'blur'},
        secret: {required: true, trigger: 'blur'},
        name: {required: true, trigger: 'blur'},
        microType: {required: true, trigger: 'blur'},
        businessType: {required: true, type: 'array', trigger: 'blur'},
        provinceCode: {required: true, trigger: 'blur'},
        cityCode: {required: true, trigger: 'blur'},
        managerName: {required: true, trigger: 'blur'},
        managerPhone: {required: true, trigger: 'blur'},
        serviceEndTime: {required: true, trigger: 'blur'},
        corporationId: {required: true, trigger: 'blur'}
    };
    columns: Array<any> = [
        {
            title: '名称',
            key: 'name',
            minWidth: 160,
            fixed: 'left'
        },
        {
            title: '小程序操作',
            minWidth: 340,
            render: (h: any, p: any) => {
                let self: any = this;
                let auditStatusStr = '';
                switch (p.row.auditStatus) {
                    case 0:
                        auditStatusStr = '提交审核';
                        break;
                    case 1:
                        auditStatusStr = '已提交审核';
                        break;
                    case 2:
                        auditStatusStr = '审核通过';
                        break;
                    case 3:
                        auditStatusStr = '审核拒绝';
                        break;
                    case 4:
                        auditStatusStr = '延后审核';
                        break;
                }
                return h('ButtonGroup', {

                }, [
                    h('Button', {
                        props: {
                            size: 'small'
                        },
                        on: {
                            click() {
                                self.templateModal.row = p.row;
                                self.$Message.loading({content: '加载中'})
                                Common.getMpTemplateList().then((body: any) => {
                                    if (body.code === 0) {
                                        self.$Message.destroy();
                                        self.templateModal.show = true;
                                        self.templateModal.tempList = body.data;
                                    }
                                })
                            }
                        }
                    }, '上传体验版'),
                    h('Button', {
                        props: {
                            size: 'small',
                            type: 'success'
                        },
                        on: {
                            click() {
                                self.qrCodeModal.title = `${p.row.name}体验二维码`;
                                self.qrCodeModal.path = `${config.baseUrl}${Common.getTyCodePath}?appId=${p.row.id}&path=pages/index/main`;
                                self.qrCodeModal.show = true;
                            }
                        }
                    }, '体验二维码'),
                    h('Button', {
                        props: {
                            size: 'small',
                            type: 'info'
                        },
                        on: {
                            click() {
                                self.$Modal.confirm({
                                    title: '提示',
                                    content: '确认提交审核？',
                                    onOk() {
                                        Micro.audit({appId: p.row.id}).then((body: any) => {
                                            if (body.code === 0) {
                                                self.$Message.info('提交成功');
                                                self.$refs['el-table'].flush();
                                            }
                                        })
                                    }
                                })
                            }
                        }
                    }, auditStatusStr),
                    h('Button', {
                        props: {
                            size: 'small',
                            type: 'primary',
                            disabled: p.row.releaseStatus===0
                        },
                        on: {
                            click() {
                                self.$Modal.confirm({
                                    title: '提示',
                                    content: '确认发布吗？',
                                    onOk() {
                                        Micro.release({appId: p.row.id}).then((body: any) => {
                                            if (body.code === 0) {
                                                self.$Message.info('发布成功');
                                                self.$refs['el-table'].flush();
                                            }
                                        })
                                    }
                                })
                            }
                        }
                    }, '发布')
                ])
            }
        },
        {
            title: 'AppId',
            key: 'id',
            minWidth: 180
        },
        {
            title: 'secret',
            key: 'secret',
            minWidth: 180
        },
        {
            title: '状态',
            key: 'status',
            minWidth: 120,
            render: (h: any, p: any) => h('p', p.row.status===0?'关闭服务': p.row.status===1?'服务中...': '-')
        },
        {
            title: '管理者',
            key: 'managerName',
            minWidth: 120
        },
        {
            title: '管理者手机',
            key: 'managerPhone',
            minWidth: 140
        },
        {
            title: '认证用户数',
            key: 'authNum',
            minWidth: 120
        },
        {
            title: '可创建店铺数量',
            key: 'maxStore',
            minWidth: 140
        },
        {
            title: '交易总金额',
            key: 'transFee',
            minWidth: 120,
            render: (h: any, p: any) => h('p', (p.row.transFee / 100).toFixed(2))
        },
        {
            title: '交易次数',
            key: 'transNum',
            minWidth: 120
        },
        {
            title: '用户使用量',
            key: 'userNum',
            minWidth: 120
        },
        {
            title: '省',
            key: 'province',
            minWidth: 160,
            render: (h: any, p: any) => h('p', `${p.row.province}(${p.row.provinceCode})`)
        },
        {
            title: '市',
            key: 'city',
            minWidth: 180,
            render: (h: any, p: any) => h('p', `${p.row.city}(${p.row.cityCode})`)
        },
        {
            title: '描述',
            key: 'desc',
            minWidth: 160,
            tooltip: true
        },
        {
            title: '服务到期时间',
            key: 'serviceEndTime',
            minWidth: 180
        },
        {
            title: '创建时间',
            key: 'createTime',
            minWidth: 180
        }
    ];
    created() {
        Common.businessTypeList().then((body: any) => {
            this.businessTypeList = body.data;
        });
        Common.getSelfMpTemplateList().then((body: any) => {
            this.microTypeList = body.data;
        })
        Common.provinceList().then((body: any) => {
            this.provinceList = body.data;
        });
    }
    createNew() {
        delete this.formData.id;
    }
    edit(row: any) {
        this.provinceChange(row.provinceCode).then(() => this.formData = row);
    }
    submit() {
        let self: any = this;
        let params = {...self.formData};
        self.$refs['el-form'].validate((valid: boolean) => {
            if (valid) {
                self.$refs['el-table'].doSubmit(params).then(() => {

                });
            }
        })
    }
    provinceChange(id: string) {
        return new Promise(resolve =>
            Common.cityList(id).then((body: any) => {
                this.cityList = body.data;
                resolve();
            })
        )
    }
    resetPwd(row: any) {
        let self: any = this;
        this.$Modal.confirm({
            title: '确定重置密码吗？',
            onOk() {
                Micro.resetMasterPass(row.id).then((body: any) => {
                    if (body.code === 0) {
                        self.pwdModal.show = true;
                        self.pwdModal.content = body.data;
                    }
                })
            }
        });
    }
    doCopy() {
        this.$copyText(this.pwdModal.content).then(() => this.$Message.info('复制成功'));
    }
    getMpCfg(row: any) {
        this.$Message.loading('请稍后');
        Micro.getMpCfg(row.id).then((body: any) => {
            if (body.code === 0) {
                this.$Message.destroy();
                this.cfgModal.info = body.data;
                this.cfgModal.show = true;
                this.$nextTick(() => this.$forceUpdate());
            }
        })
    }
    setMpCfg() {
        this.$Message.loading('请稍后');
        Micro.setMpCfg(this.cfgModal.info).then((body: any) => {
            if (body.code === 0) {
                this.$Message.destroy();
                this.$Message.info('设置成功');
            }
        })
    }
    corporationRemoteSearch(query: string) {
        Merchants.getSummaryList(query).then((body: any) => this.corporationList = body.data)
    }
}
