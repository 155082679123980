import {BaseRequest} from "@/request/BaseRequest";

class Micro extends BaseRequest {

    public requestPath: string = '/plat/micro';

    /**
     * 获取商户信息
     * @param id
     */
    getWxInfo(id: string): any {
        return this.get(`${this.requestPath}/wxInfo/get?merchantId=${id}`);
    }

    /**
     * 设置商户信息
     * @param data
     */
    setWxInfo(data: {
        appId: string, //小程序微信对应的APPid
        cardFront: string, //身份证正面
        cardBack: string, //身份证背面
        foodPermit: string, //食品许可证图片地址,(做食品餐饮的才有)
        license: string, //营业执照图片地址
        masterName: string, //负责人名字
        masterPhone: string, //负责人电话
        microAccount: string, //小程序账号登录账号
        microPassword: string, //小程序账号密码
        mpAccount: string, //公众号的账号
        mpId: string, //公众号ID
        mpPassword: string, //公众号账号密码
        mpSecret: string, //公众号的secret
        others: Array<string>, //其它附件
        secret: string, //小程序secret
        subMchId: string //微信子商户号, 主/子商户用
    }): any {
        return this.post(`${this.requestPath}/wxInfo/set`, data);
    }

    resetMasterPass(id: string): any {
        return this.post(`${this.requestPath}/resetMasterPass`, {id: id});
    }

    /**
     * 获取小程序配置
     * @param appId
     */
    getMpCfg(appId: string): any {
        return this.post(`${this.requestPath}/AttributionGet`, {appId: appId})
    }

    /**
     * 设置小程序配置
     * @param data
     */
    setMpCfg(data: any) {
        return this.post(`${this.requestPath}/AttributionSet`, data)
    }

    /**
     * 上传代码
     * @param data
     */
    commitCode(data: any): any {
        return this.post(`${this.requestPath}/code/commit`, data)
    }

    /**
     * 提交审核
     * @param data
     */
    audit(data: {appId: string}): any {
        return this.post(`${this.requestPath}/code/audit`, data);
    }

    /**
     * 撤销审核
     * @param data
     */
    undoAudit(data: {appId: string}): any {
        return this.post(`${this.requestPath}/code/undoAudit`, data);
    }

    /**
     * 发布
     * @param data
     */
    release(data: {appId: string}): any {
        return this.post(`${this.requestPath}/release`, data)
    }


}

const c = new Micro();
export {c as Micro};